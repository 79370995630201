import React from "react";
import { Tooltip, Avatar } from "antd";
import classNames from "classnames";

import "./index.css";

const AvatarList = ({ children, size, ...other }) => {
  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, {
      size
    })
  );

  return (
    <div {...other} className={"avatarList"}>
      <ul> {childrenWithProps} </ul>
    </div>
  );
};

const Item = ({ src, size, tips, onClick = () => {} }) => {
  const cls = classNames("avatarItem", {
    "avatarItemLarge": size === "large",
    "avatarItemSmall": size === "small",
    "avatarItemMini": size === "mini"
  });

  return (
    <li className={cls} onClick={onClick}>
      {tips ? (
        <Tooltip title={tips}>
          <Avatar src={src} size={size} style={{ cursor: "pointer" }} />
        </Tooltip>
      ) : (
        <Avatar src={src} size={size} />
      )}
    </li>
  );
};

AvatarList.Item = Item;

export default AvatarList;
