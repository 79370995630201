import React from "react";

const Footer = () => (
  <footer>
    <p>Made with ❤ by <a href="https://www.dootech.com" target="_blank" rel="noopener noreferrer">Dootech</a></p>
    <p>This project is not affiliated with the GitHub company in any way.</p>
  </footer>
);

export default Footer;
