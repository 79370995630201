import React from "react";
import { Icon } from "antd";

const IconText = ({ type, text }) => (
  <span className="hover-highlight">
    <Icon type={type} style={{ marginRight: 8 }} />
    {text}
  </span>
);

export default IconText;
