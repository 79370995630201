import React from "react";
import "./index.css";

const BmcButton = () => (
  <span>
    <a
      className="bmc-btn"
      href="https://www.buymeacoffee.com/hoducha"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="https://www.buymeacoffee.com/assets/img/BMC-btn-logo.svg"
        alt="Buy Me A Coffee"
      />
      <span>Buy me a coffee</span>
    </a>
  </span>
);

export default BmcButton;
