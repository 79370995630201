import React, { Component } from "react";
import { Button, Modal, Spin, notification, Divider, Drawer } from "antd";
import uniq from "lodash/uniq";
import firebase from "firebase/app";
import "firebase/auth";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import Logo from "./components/Logo";
import Footer from "./components/Footer";
import RepoList from "./components/RepoList";
import IndexingProgress from "./components/IndexingProgress";
import ServiceIntro from "./components/ServiceIntro";
import SearchInput from "./components/SearchInput";
import UserMenu from "./components/UserMenu";
import BmcButton from "./components/BmcButton";
import FAQ from "./components/FAQ";
import "./App.css";
import logo from "./assets/logo.png";
import firebaseConfig from "./firebaseConfig";
import { downloadData } from "./services";
import { octoAuthenticate } from "./services/github";
import {
  DB_VERSION,
  getDBInfo,
  initializeDB,
  reinitializeDB,
  search,
  buildIndex,
  queryUsers
} from "./services/db";

firebase.initializeApp(firebaseConfig);

function checkDBVersion() {
  const localDBVersion = localStorage.getItem("DBVersion");
  if (!localDBVersion || localDBVersion === DB_VERSION) {
    return true;
  }
  return false;
}

function getCurrentUser() {
  return JSON.parse(localStorage.getItem("currentUser"));
}

function setCurrentUser(user) {
  localStorage.setItem("currentUser", JSON.stringify(user));
}

class App extends Component {
  state = {
    searchResult: {
      repos: [],
      users: [],
      total: 0,
      loading: false
    },
    isSignedIn: undefined,
    indexing: { ...this.initIndexingState },
    dbInfo: {
      lastUpdated: null,
      usersCount: 0,
      reposCount: 0,
      loading: false
    },
    isFAQVisible: false
  };

  initIndexingState = {
    usersCount: 0,
    reposCount: 0,
    usersProcessed: 0,
    isRunning: false,
    messages: [],
    buildIndexTimeElapsed: 0
  };

  firebaseuiConfig = {
    signInFlow: "popup",
    signInOptions: [firebase.auth.GithubAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult: async (authResult, redirectUrl) => {
        const { uid, displayName, photoURL } = authResult.user;
        const { accessToken } = authResult.credential;
        octoAuthenticate(accessToken);

        const lastUser = getCurrentUser();
        const currentUser = {
          uid,
          displayName,
          photoURL,
          accessToken
        };
        setCurrentUser(currentUser);
        if (!lastUser || currentUser.uid !== lastUser.uid) {
          await this.reCrawlAndIndex();
        }

        return false; // Avoid redirects after sign-in.
      }
    }
  };

  updateDBInfo = () => {
    this.setState({ dbInfo: { ...this.state.dbInfo, loading: true } });
    getDBInfo().then(({ usersCount, reposCount }) => {
      this.setState({
        dbInfo: {
          ...this.state.dbInfo,
          usersCount,
          reposCount,
          lastUpdated: localStorage.getItem("lastUpdated"),
          loading: false
        }
      });
    });
  };

  clearData = async () => {
    this.setState({ dbInfo: { ...this.state.dbInfo, loading: true } });
    await reinitializeDB();
    this.updateDBInfo();
    notification["success"]({
      message: "Data cleared",
      description: "Your data has been cleared successfully."
    });
  };

  componentDidMount() {
    initializeDB();

    this.updateDBInfo();

    // Listen to the Firebase Auth state and set the local state.
    this.unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged(async user => {
        this.setState({ isSignedIn: !!user, searchResult: null });
        if (user) {
          // Octokit authenticating
          const currentUser = getCurrentUser();
          if (currentUser) {
            octoAuthenticate(currentUser.accessToken);
          }

          // Migrate database
          if (checkDBVersion() === false) {
            Modal.info({
              title: "Upgrade Notice",
              content:
                "The app has just upgraded with some major changes that require to rebuilding indexes.",
              onOk: () => {
                this.reCrawlAndIndex();
                localStorage.setItem("DBVersion", DB_VERSION);
              }
            });
          }
        }
      });
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  // Destroy the databases and rebuilding indexes
  reCrawlAndIndex = async () => {
    if (this.state.indexing.isRunning) {
      return;
    }
    await reinitializeDB();
    await this.crawlAndIndex();
  };

  crawlAndIndex = async () => {
    if (this.state.indexing.isRunning) {
      return;
    }
    this.setState({ indexing: { ...this.initIndexingState, isRunning: true } });

    let crawling;
    try {
      crawling = await downloadData();
    } catch (e) {
      if (
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED"
      ) {
        Modal.warning({
          title: "Quota Exceeded Error",
          content:
            "You have reached the quota limit for the IndexedDB of the browser. Please read our FAQ for more information.",
          onOk: () => {
            window.location.reload();
          }
        });
      } else {
        throw e;
      }
    }
    if (!crawling) {
      return;
    }

    crawling.subscribe({
      next: info => {
        this.setState({
          indexing: { ...this.state.indexing, ...info }
        });
      },
      error: err => console.log(err),
      complete: async () => {
        // Building indexes
        this.setState({
          indexing: {
            ...this.state.indexing,
            messages: [...this.state.indexing.messages, "Building indexes..."]
          }
        });
        const startTime = new Date().getTime();
        const interval = setInterval(() => {
          const buildIndexTimeElapsed = new Date().getTime() - startTime;
          this.setState({
            indexing: { ...this.state.indexing, buildIndexTimeElapsed }
          });
        }, 1000);
        await buildIndex();
        clearInterval(interval);

        // Show notification only if updating the database
        if (this.state.dbInfo.lastUpdated) {
          notification["success"]({
            message: "The database updated successfully",
            description:
              "Your database of starred repositories has been updated successfully."
          });
        }

        // Update the progress and DB info
        const lastUpdated = new Date();
        this.setState({
          indexing: {
            ...this.state.indexing,
            isRunning: false,
            messages: []
          }
        });
        localStorage.setItem("lastUpdated", lastUpdated);
        this.updateDBInfo();
      }
    });
  };

  handleSearch = async keyword => {
    try {
      this.setState({ searchResult: { loading: true } });
      const result = await search(keyword);
      const { total_rows: total, rows: repos } = result;

      let userIds = [];
      repos.forEach(item => {
        userIds = userIds.concat(item.doc.starred_by);
      });
      userIds = uniq(userIds);
      const users = await queryUsers(userIds);

      this.setState({ searchResult: { total, repos, users, loading: false } });
    } catch (err) {
      console.log(err);
    }
  };

  renderResult = () => {
    const { searchResult } = this.state;
    if (searchResult) {
      const { total, repos, users, loading } = searchResult;
      if (loading) {
        return (
          <div className="search-result">
            <div className="search-loading">
              <Spin />
            </div>
          </div>
        );
      }
      // let total = 10;
      return (
        <div className="search-result">
          <div className="bmc-btn-wrapper">
            <BmcButton />
          </div>
          <div className="result-info">
            <span className="total-results">Total: {total} results</span>
          </div>
          <RepoList items={repos} users={users} />
        </div>
      );
    }
  };

  showFAQ = () => this.setState({ isFAQVisible: true });

  closeFAQ = () => this.setState({ isFAQVisible: false });

  renderFAQ = () => (
    <Drawer
      width="50%"
      placement="right"
      closable={false}
      onClose={this.closeFAQ}
      visible={this.state.isFAQVisible}
    >
      <FAQ />
      <div className="faq-buttons">
        <Button
          type="primary"
          style={{ marginRight: 8 }}
          onClick={this.closeFAQ}
        >
          Close
        </Button>
      </div>
    </Drawer>
  );

  renderIntro = () => (
    <div className="container">
      <div className="login-container">
        <Logo
          logo={logo}
          height={200}
          tooltip="Need helps?"
          onClick={this.showFAQ}
        />
        <h1>OctoSearch</h1>
        <p>
          Helps you search the repositories starred by people you follow on Github
        </p>
        <StyledFirebaseAuth
          uiConfig={this.firebaseuiConfig}
          firebaseAuth={firebase.auth()}
        />
      </div>
      <ServiceIntro />
      {this.renderFAQ()}
      <Footer />
    </div>
  );

  renderSearchContent = () => (
    <div>
      <div className="search-button">
        <SearchInput handleSearch={this.handleSearch} />
        {this.renderDatabaseStatus()}
      </div>
      {this.renderResult()}
    </div>
  );

  renderDatabaseStatus = () => {
    const { indexing, dbInfo } = this.state;
    if (dbInfo.loading) {
      return (
        <div className="db-info">
          <Spin />
        </div>
      );
    }

    let lastUpdatedStr = "None";
    if (dbInfo.lastUpdated) {
      lastUpdatedStr = new Date(dbInfo.lastUpdated).toLocaleDateString(
        "en-US",
        {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric"
        }
      );
    }
    return (
      <div className="db-info">
        <span>
          Total: {dbInfo.usersCount} users, {dbInfo.reposCount} repos
        </span>
        <Divider type="vertical" />
        {lastUpdatedStr && <span>Last updated: {lastUpdatedStr}</span>}
        <Divider type="vertical" />
        <Button
          size="small"
          onClick={this.crawlAndIndex}
          disabled={indexing.isRunning}
        >
          Update Now
        </Button>
      </div>
    );
  };

  render() {
    const { isSignedIn, indexing } = this.state;

    if (isSignedIn === undefined) {
      return (
        <div className="full-viewport">
          <Spin />
        </div>
      );
    }

    if (!isSignedIn) {
      return this.renderIntro();
    }

    return (
      <div className="container">
        <header>
          <div className="user-menu">
            <UserMenu
              user={firebase.auth().currentUser}
              logout={() => firebase.auth().signOut()}
              clearData={this.clearData}
              updateData={() => this.crawlAndIndex()}
              showFAQ={this.showFAQ}
            />
          </div>
        </header>

        <div className="main">
          <Logo
            logo={logo}
            height={120}
            tooltip="Need helps?"
            onClick={this.showFAQ}
          />
          {indexing.isRunning && <IndexingProgress {...indexing} />}
          {!indexing.isRunning && this.renderSearchContent()}
        </div>

        {this.renderFAQ()}
        <Footer />
      </div>
    );
  }
}

export default App;
