import React, { Component } from "react";
import { Input } from "antd";

class SearchInput extends Component {
  onKeypress = event => {
    const searchInput = document.getElementById("search-input");
    if (searchInput && searchInput !== document.activeElement && event.key === "/") {
      event.preventDefault();
      searchInput.focus();
    }
  };

  componentDidMount() {
    document.addEventListener("keypress", this.onKeypress);
  }

  componentWillUnmount() {
    document.removeEventListener("keypress", this.onKeypress);
  }

  render() {
    const { handleSearch } = this.props;
    return (
      <Input.Search
        id="search-input"
        placeholder="input search text"
        style={{ width: 300 }}
        onSearch={handleSearch}
        enterButton
      />
    );
  }
}

export default SearchInput;
