import React, { Component } from "react";
import { Dropdown, Avatar, Menu, Icon } from "antd";

class UserMenu extends Component {
  handleMenuClick = ({ key }) => {
    const { logout, updateData, clearData, showFAQ } = this.props;
    if (key === "updateData") {
      return updateData();
    }
    if (key === "clearData") {
      return clearData();
    }
    if (key === "showFAQ") {
      return showFAQ();
    }
    if (key === "donate") {
      return window.open("https://www.buymeacoffee.com/hoducha");
    }
    if (key === "logout") {
      return logout();
    }
  };

  render() {
    const { user } = this.props;
    const { displayName, photoURL } = user;
    const menu = (
      <Menu selectedKeys={[]} onClick={this.handleMenuClick}>
        <Menu.Item key="updateData">
          <Icon type="sync" /> Update Data
        </Menu.Item>
        <Menu.Item key="clearData">
          <Icon type="code-o" /> Clear Data
        </Menu.Item>
        <Menu.Item key="showFAQ">
          <Icon type="question-circle-o" /> FAQ
        </Menu.Item>
        <Menu.Item key="donate">
          <Icon type="heart-o" /> Donate
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="logout">
          <Icon type="logout" /> Logout
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu}>
        <div>
          <div>
            <Avatar size="large" src={photoURL} />
          </div>
          <div>{displayName}</div>
        </div>
      </Dropdown>
    );
  }
}

export default UserMenu;
