import pick from "lodash/pick";

const octokit = require("@octokit/rest")({
  headers: {
    // Workaround until the issue #885 is fixed
    // https://github.com/octokit/rest.js/issues/885
    accept: "application/vnd.github.v3.star+json"
  }
});

export function octoAuthenticate(token) {
  octokit.authenticate({
    type: "oauth",
    token
  });
}

export async function getAllPaginate(method, params) {
  let response = await method(params);
  let { data } = response;
  while (octokit.hasNextPage(response)) {
    response = await octokit.getNextPage(response);
    data = data.concat(response.data);
  }
  return data;
}

export async function fetchFollowing(callback, debugUser) {
  let params = {
    per_page: 100,
    headers: {
      // Workaround until the issue #885 is fixed
      // https://github.com/octokit/rest.js/issues/885
      accept: "application/vnd.github.v3+json"
    }
  };
  let result;
  if (debugUser) {
    result = await getAllPaginate(octokit.users.getFollowingForUser, {
      ...params,
      username: debugUser
    });
  } else {
    result = await getAllPaginate(octokit.users.getFollowing, params);
  }

  result = result.map(user =>
    pick(user, "id", "login", "avatar_url", "html_url")
  );

  // Process array in parallel
  const promises = result.map(callback);
  await Promise.all(promises);

  return result;
}

export async function fetchStarredReposForUser(userId, username, callback) {
  let result = await getAllPaginate(octokit.activity.getStarredReposForUser, {
    username,
    per_page: 100,
    headers: {
      accept: "application/vnd.github.v3.star+json"
    }
  });

  // Process array in parallel
  const promises = result.map(async item => {
    const repo = pick(
      item.repo,
      "id",
      "name",
      "full_name",
      "description",
      "homepage",
      "html_url",
      "language",
      "license.spdx_id",
      "open_issues_count",
      "stargazers_count",
      "watchers_count",
      "forks",
      "created_at",
      "updated_at"
    );
    callback(repo, userId, item.starred_at);
  });
  await Promise.all(promises);

  return result;
}
