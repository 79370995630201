import PouchDB from "pouchdb";

const searchPlugin = require("pouchdb-quick-search");
PouchDB.plugin(searchPlugin);

let usersDB, reposDB;

const INDEX_FIELDS = ["name", "full_name", "description"];

// Increase the version will force user to recreate DB and indexes
export const DB_VERSION = "0.1.0";

export async function initializeDB() {
  // By default, PouchDB are designed to store all document revisions forever.
  // Turn on the auto_compaction to optimize its current storage usage.
  reposDB = new PouchDB("repos", { auto_compaction: true });
  usersDB = new PouchDB("users", { auto_compaction: true });
}

export async function reinitializeDB() {
  if (usersDB) {
    await usersDB.destroy();
  }
  if (reposDB) {
    await deleteIndex();
    await reposDB.destroy();
  }
  initializeDB();
}

export async function getDBInfo() {
  try {
    const { doc_count: usersCount } = await usersDB.info();
    const { doc_count: reposCount } = await reposDB.info();
    return { usersCount, reposCount };
  } catch (e) {
    console.log(e);
    return {};
  }
}

export async function search(query) {
  return reposDB.search({
    query,
    fields: INDEX_FIELDS,
    include_docs: true
  });
}

export async function buildIndex() {
  return reposDB.search({
    fields: INDEX_FIELDS,
    build: true
  });
}

export async function deleteIndex() {
  return reposDB.search({
    fields: INDEX_FIELDS,
    destroy: true
  });
}

export async function queryUsers(userIds) {
  const users = await usersDB.allDocs({
    include_docs: true,
    keys: userIds
  });
  return users.rows;
}

export async function saveUser(user) {
  const docId = user.id.toString();
  usersDB
    .get(docId)
    .then(doc => {
      // update doc
      usersDB.put({ ...doc, ...user });
    })
    .catch(e => {
      // add new doc
      if (e.name === "not_found") {
        usersDB.put({
          _id: docId,
          ...user
        });
      }
    });
}

export async function saveStarredRepo(repo, starredBy, lastStarredAt) {
  const docId = repo.id.toString();
  reposDB
    .get(docId)
    .then(doc => {
      // update the doc
      let { starred_by, last_starred_at } = doc;
      if (starred_by.indexOf(starredBy) === -1) {
        starred_by.push(starredBy);
      }
      if (last_starred_at < lastStarredAt) {
        last_starred_at = lastStarredAt;
      }
      return reposDB.put({ ...doc, ...repo, starred_by, last_starred_at });
    })
    .catch(e => {
      if (e.name === "not_found") {
        // add a new doc
        reposDB
          .put({
            _id: docId,
            ...repo,
            starred_by: [starredBy],
            last_starred_at: lastStarredAt
          })
          .catch(err => {
            // this may happen when calling tbe function in parallel
            if (err.name === "conflict") {
              saveStarredRepo(repo, starredBy, lastStarredAt);
            } else {
              console.debug(err);
            }
          });
      } else if (e.name === "conflict") {
        // this may happen when calling tbe function in parallel
        saveStarredRepo(repo, starredBy, lastStarredAt);
      } else {
        console.debug(e);
      }
    });
}
