import React from "react";
import { Collapse, Tag } from "antd";
import BmcButton from "./BmcButton";

const { Panel } = Collapse;
let index = 0;

const FAQ = () => (
  <div style={{ marginBottom: 30 }}>
    <h2 style={{ textAlign: "center" }}>Frequently Asked Questions</h2>
    <Collapse>
      <Panel header="How big is the database?" key={++index}>
        <p>
          It depends on your data. The following tests will give you the idea of the database size.
          <ul>
            <li>With the 46 following users and 8,308 starred repos, it takes 39.8 MB.</li>
            <li>With the 171 following users and 28,638 starred repos, it takes 137 MB.</li>
          </ul>
        </p>
      </Panel>
      <Panel header="How much data can be stored?" key={++index}>
        <p>
          OctoSearch uses IndexedDB, the quota limit of IndexedDB depends on
          your browsers. For more information, see{" "}
          <a
            href="https://www.html5rocks.com/en/tutorials/offline/quota-research/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Working with quota on mobile browsers.
          </a>
        </p>
        <p>We suggest you use Google Chrome for consistent result.</p>
      </Panel>
      <Panel header="Does it work on mobile devices?" key={++index}>
        <p>
          Yes, it could work on mobile device; but the limitation of mobile
          browsers are vary so we encourage you to use the app on desktop
          browser.
        </p>
      </Panel>
      <Panel header="Does it work offline?" key={++index}>
        <p>Yes, once the data crawled, the app can work offline.</p>
      </Panel>
      <Panel header="Is it free?" key={++index}>
        <p>Yes, it's free. If you considering support us:</p>
        <p>
          <BmcButton /> or{" "}
          <a
            href="https://www.producthunt.com/posts/octosearch"
            target="_blank"
            rel="noopener noreferrer"
          >
            Vote on ProductHunt
          </a>
        </p>
      </Panel>
      <Panel
        header={
          <span>
            <Tag>tip</Tag>
            <span>Press / to focus on the search input</span>
          </span>
        }
        key={++index}
      >
        <p>Press / (forward slash) to focus on the search input.</p>
      </Panel>
    </Collapse>
  </div>
);

export default FAQ;
