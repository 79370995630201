import React from "react";
import { Row, Col, Icon } from "antd";
import screenshot1 from "../assets/crawling.gif";
import screenshot2 from "../assets/searching.gif";

const ServiceIntro = () => (
  <div className="service-info">
    <Row
      gutter={{ xs: 8, sm: 16 }}
      type="flex"
      justify="center"
      className="intro-section section-dark"
    >
      <Col className="aside-img" xs={24} sm={24} md={12} xl={10} xxl={8}>
        <img style={{ maxWidth: 450 }} src={screenshot1} alt="screenshot" />
      </Col>
      <Col className="aside-text" xs={24} sm={24} md={12} xl={10} xxl={8}>
        <h2>Crawling engine</h2>
        <p>
          <Icon type="star-o" />Crawling data in parallel but respect the GitHub rate
          limit
          <br />
          <Icon type="star-o" />The data is stored locally and you control over
          your data
          <br />
          <Icon type="star-o" />Simple and beautiful UI
        </p>
      </Col>
    </Row>
    <Row
      gutter={{ xs: 8, sm: 16 }}
      type="flex"
      justify="center"
      className="intro-section"
    >
      <Col className="aside-text" xs={24} sm={24} md={12} xl={10} xxl={8}>
        <h2>Full-text search engine</h2>
        <p>
          <Icon type="star-o" />Offline-first Progressive Web App
          <br />
          <Icon type="star-o" />Very efficient and accurate full-text search
          engine
          <br />
          <Icon type="star-o" />The tokenization/stemming/stopword
          optimized for English text
        </p>
      </Col>
      <Col className="aside-img" xs={24} sm={24} md={12} xl={10} xxl={8}>
        <img src={screenshot2} alt="screenshot" />
      </Col>
    </Row>
  </div>
);

export default ServiceIntro;
