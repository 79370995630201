import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Progress } from "antd";
import "./index.css";

class IndexingProgress extends Component {
  scrollToBottom = () => {
    const { messageList } = this.refs;
    const scrollHeight = messageList.scrollHeight;
    const height = messageList.clientHeight;
    const maxScrollTop = scrollHeight - height;
    ReactDOM.findDOMNode(messageList).scrollTop =
      maxScrollTop > 0 ? maxScrollTop : 0;
  };

  componentDidUpdate(prevProps) {
    if (this.props.messages.length !== prevProps.messages.length) {
      this.scrollToBottom();
    }
  }

  render() {
    const {
      usersCount,
      usersProcessed,
      messages,
      reposCount,
      buildIndexTimeElapsed
    } = this.props;

    // Assume both crawling and building indexes take 50% of the total time
    let percent = 0;
    if (buildIndexTimeElapsed === 0) {
      // crawling
      percent = (usersProcessed / usersCount) * 50;
    } else if (reposCount > 0) {
      // indexing, assume 200 repos are indexed per second
      const estimatedBuildIndexTime = (reposCount / 200) * 1000;
      // because it's just estimated time so only run to maximum 95%
      if (buildIndexTimeElapsed < estimatedBuildIndexTime) {
        percent = (buildIndexTimeElapsed / estimatedBuildIndexTime) * 45 + 50;
      } else {
        percent = 95;
      }
    }

    return (
      <div className="indexing-info">
        <Progress percent={Math.round(percent)} status="active" />
        <div ref="messageList" className="indexing-logs">
          {messages.map((msg, index) => <div key={index}>{msg}</div>)}
        </div>
      </div>
    );
  }
}

export default IndexingProgress;
