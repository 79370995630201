import { mapLimit, asyncify } from "async";
import { Observable } from "rxjs";
import { fetchFollowing, fetchStarredReposForUser } from "./github";
import { saveUser, saveStarredRepo } from "./db";

const sleep = ms => new Promise(res => setTimeout(res, ms));

export async function downloadData() {
  // For debugging purpose
  const urlParams = new URLSearchParams(window.location.search);
  const debugUser = urlParams.get("debugUser");
  let limitFollowing = urlParams.get("limitFollowing");

  let users = await fetchFollowing(saveUser, debugUser);

  if (limitFollowing && (limitFollowing = parseInt(limitFollowing))) {
    users = users.slice(0, limitFollowing);
  }

  const concurrent = 5;
  let progress = {
    usersCount: users.length,
    reposCount: 0,
    usersProcessed: 0,
    messages: ["Crawling data..."]
  };
  let isSleeping = false;

  return Observable.create(observer => {
    mapLimit(
      users,
      concurrent,
      asyncify(async user => {
        let result,
          retries = 0;
        while (retries < 3) {
          try {
            result = await fetchStarredReposForUser(
              user.id.toString(),
              user.login,
              saveStarredRepo
            );
            break;
          } catch (e) {
            if (e.name === "HttpError") {
              if (!isSleeping) {
                isSleeping = true;
                progress = {
                  ...progress,
                  messages: [
                    ...progress.messages,
                    "API rate limit is exceeded. Please wait for 60 seconds..."
                  ]
                };
                observer.next(progress);
              }
              retries++;
              await sleep(60000);
              isSleeping = false;
            } else {
              throw e; // bubble the other kind of exceptions
            }
          }
        }

        if (result) {
          progress = {
            ...progress,
            reposCount: progress.reposCount + result.length,
            usersProcessed: progress.usersProcessed + 1,
            messages: [
              ...progress.messages,
              `- ${user.login} has starred ${result.length} repositories`
            ]
          };
          observer.next(progress);
        } else {
          console.debug(`Failed to fetch starred repositories for user: ${user.login}`);
        }
        
        return result;
      }),
      (err, results) => {
        if (err) throw err;
        observer.complete();
      }
    );
  });
}
