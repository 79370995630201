import React from "react";
import { Tooltip } from "antd";

const Logo = ({ width, height, logo, tooltip, onClick }) => (
  <Tooltip title={tooltip}>
    <img src={logo} width={width} height={height} style={{ cursor: "pointer" }} alt="logo" onClick={onClick} />
  </Tooltip>
);

export default Logo;
