import React from "react";
import { List } from "antd";
import GitHubColors from "github-colors";
import { formatDistance } from "date-fns";
import IconText from "./IconText";
import AvatarList from "./AvatarList";

const LanguageBadge = ({ language }) => {
  return (
    <span className="language-badge">
      <span
        className="repo-language-color"
        style={{ backgroundColor: GitHubColors.get(language, true).color }}
      />
      <span>{language || "Unknown"}</span>
    </span>
  );
};

const UserAvatarList = ({ users }) => {
  if (!users || users.length === 0) {
    return null;
  }

  return (
    <AvatarList size="large">
      {users.map(u => (
        <AvatarList.Item
          key={u.doc.id}
          tips={u.doc.login}
          src={u.doc.avatar_url}
          onClick={() => window.open(u.doc.html_url)}
        />
      ))}
    </AvatarList>
  );
};

const RepoList = ({ items, users }) => (
  <List
    itemLayout="vertical"
    size="large"
    dataSource={items}
    pagination={{
      pageSize: 10
    }}
    renderItem={item => (
      <List.Item
        key={item.doc.id}
        actions={[
          <LanguageBadge language={item.doc.language} />,
          <IconText type="star-o" text={item.doc.stargazers_count} />,
          <IconText type="eye-o" text={item.doc.watchers_count} />,
          <IconText type="fork" text={item.doc.forks} />,
          <span>
            Updated{" "}
            {formatDistance(item.doc.updated_at, new Date(), {
              addSuffix: true
            })}
          </span>
        ]}
        extra={
          <UserAvatarList
            users={
              users && users.filter(u => item.doc.starred_by.indexOf(u.id) > -1)
            }
          />
        }
      >
        <List.Item.Meta
          title={
            <a
              href={item.doc.html_url}
              className="hover-highlight"
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.doc.name}
            </a>
          }
          description={item.doc.description}
        />
      </List.Item>
    )}
  />
);

export default RepoList;
